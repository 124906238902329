import {mapMutations, mapActions, mapGetters} from "vuex";
import {DELETE_FILES_TEST} from "../../../../../store/action-types";

export default {
    name: "questionary",
    components: {},
    data() {
        return {
            checkBoxValue: [],
            inputValue: {},
            previewImages: [],
            hearChecked: [],
            messageDisabled: false,
            profileDisabled: false,
            skinChecked: [],
            problemChecked: [],
            problemSkinChecked: [],
            allergiesChecked: [],
            problemsHealthChecked: [],
            allergic_reactionsChecked: [],
            after_washingChecked: [],
            scaly_patchesChecked: [],
            redness_spotsChecked: [],
            t_zoneChecked: [],
            face_shinyChecked: [],
            matting_powderChecked: [],
            washingWithWaterChecked: [],
            pores_enlargedChecked: [],
            areas_on_your_faceChecked: [],
            inflammation_on_yourChecked: [],
            mimic_wrinklesChecked: [],
            injectableChecked: [],
            inputsCollection: [],
            optionCollection: [],
            testSend: true,
            errorName: false,
            errorSecondName: false,
            allImg: [],
            preview: [],
            files: [],
        };
    },
    methods: {
        ...mapActions({
            getTest: 'user/GET_TEST',
            saveQuestionary: 'user/SAVE_TEST',
            uploadImg: 'user/UPLOAD_IMG',
            deleteFiles: 'user/DELETE_FILES_TEST'
        }),

        showTest() {
            this.testSend = !this.testSend

            if (this.user.tests) {
                let test = () => {
                    this.user.tests.data.forEach(el => {
                        this.test.forEach(elem => {

                            if (el.test_id === elem.id) {

                                let input = document.getElementById(`${elem.title}`)
                                if (input !== null) {
                                    input.value = el.value
                                    this.inputValue[elem.title] = input.value
                                }

                                if (elem.options) {

                                    elem.options.forEach(opt => {

                                        if (opt.id === el.test_value_id) {
                                            elem.options = elem.options.filter(({id}) => id !== el.test_value_id)
                                            elem.options.push({id: opt.id, value: opt.value, checked: true})

                                        }
                                    })
                                }


                            }
                        })
                    })
                }
                setTimeout(test, 100)
            }
            if (this.testSend) {
                this.getTest()
            }


        },
        saveTest() {
            let answers = this.test
            let isEmptyInputsValue = Object.keys(this.inputValue).length
            if (isEmptyInputsValue === 0) {
                this.errorSecondName = true
                this.errorName = true
            }

            answers.forEach(item => {
                for (let key in this.inputValue) {
                    if (item.title === key) {
                        item.value = Object.values(this.inputValue[key]).join('')
                    }
                }
            })

            let tests = answers.map(answer => {
                if (answer.value !== undefined) {
                    return {
                        test_id: answer.id,
                        value: answer.value,
                    }
                }

            })
            tests = tests.filter(elem => elem !== undefined)
            this.checkBoxValue.map(el => {
                el.options.map(elem => {
                    tests.push({test_value_id: elem.id, test_id: el.id})
                })
            })
            if (this.inputValue['Прізвище'] || this.inputValue['Фамилия']) {
                this.errorSecondName = false
            } else {
                this.errorSecondName = true
            }
            if (this.inputValue['Ім\'я'] || this.inputValue['Имя']) {
                this.errorName = false
            } else {
                this.errorName = true
            }
            if (!this.errorName && !this.errorSecondName) {

                this.saveQuestionary({tests}).then(() => {
                    if (this.files.length !== 0) {
                        this.updateFiles()
                    }

                    this.$toasted.success(this.$t('testSuccessSend'));
                    this.testSend = false
                })
            } else {
                this.$toasted.error(this.$t('errorFields'));
            }

        },
        addAnswer(category, answer, is_radio = false) {
            let obj = {
                id: category.id,
                title: category.title,
                options: []
            }

            if (this.checkBoxValue.length === 0) {
                this.checkBoxValue.push(obj)
                this.test.forEach(el => {
                    if (el.options !== undefined) {
                        el.options.forEach(element => {

                            if (element.checked) {

                                this.checkBoxValue.forEach(opt => {
                                    opt.options.push(element)
                                })
                            }
                        })

                    }
                })
            }

            this.checkBoxValue.forEach(elem => {
                if (elem.id === category.id) {


                    if (elem.options.filter(({id}) => id !== answer.id).length !== elem.options.length) {
                        elem.options = elem.options.filter(({id}) => id !== answer.id)

                    }

                    if (is_radio && elem.options.length >= 1) {
                        elem.options.splice(0, 1, answer)
                    } else {
                        elem.options.push(answer)
                    }
                } else {
                    if (this.checkBoxValue.filter(({id}) => id !== obj.id).length === this.checkBoxValue.length) {
                        this.checkBoxValue.push(obj)
                        obj.options.push(answer)

                    }

                }

            })

        },
        updateFiles() {
            let fd = new FormData();
            for (let j in this.files) {
                fd.append(`files[${j}]`, this.files[j]);
            }
            this.uploadImg(fd)

        },
        handleFileChange(e) {
            let arr = Array.from(e.target.files)
            this.files = this.files.concat(arr)

            let newArr = []
            this.files.forEach(el => {
                newArr.push({path: URL.createObjectURL(el)})
            })
            this.preview = newArr
            this.allImg = this.user.tests.files.concat(this.preview)
        },
        removeFile(file) {
            const index = this.allImg.findIndex(e => e === file)


            let element = this.allImg.find(e => e === file)
            this.allImg.splice(index, 1)

            this.deleteFiles(element.id)
        },
        setCheck(key, answer) {
            this.payload.problemQuestions[key].check = answer
        },

    },

    computed: {
        ...mapGetters({
            test: 'user/test',
            user: `profile/user`,
        }),
        editTest() {
            if (this.user.tests) {
                this.user.tests.forEach(el => {

                })
            }
        }

    },
    created() {
        this.allImg = this.user.tests.files
        if (this.user.tests) {
            this.testSend = false
        }
        this.getTest().then(() => {

        })

    }

};
